import { SEARCH_KEYWORD } from '../actions/actionTypes'

const searchKeywordReducer = (state = {}, action) => {

  switch (action.type) {
    case SEARCH_KEYWORD:
      return {
        keyword: action.payload,
      }
    default:
      return state
  }
}
export default searchKeywordReducer
