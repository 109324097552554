import { SEARCH_VALUES } from '../actions/actionTypes'

const searchReducer = (state = [], action) => {
  switch (action.type) {
    case SEARCH_VALUES:
      return {
        ...state,
        searchData: action.payload,
      }
    default:
      return state
  }
}
export default searchReducer
