import { PUBLICATION_SEARCK_KEYWORD } from '../actions/actionTypes'

const publicationSearchKeywordReducer = (state = {}, action) => {
  switch (action.type) {
    case PUBLICATION_SEARCK_KEYWORD:
      return {
        publicationKeyword: action.payload,
      }
    default:
      return state
  }
}
export default publicationSearchKeywordReducer
