import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'
import { t } from 'i18next'
import './LoadingPopup.css'
const LoadingPopup = (props) => {
  const [loadingPopup, setLoadingPopup] = useState(true)
  return (
    <div className="loading-popup">
      <Modal
        show={loadingPopup}
        className="loading-popup-modal overflow-hidden"
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className="loading-spinner-logo">
            <div className="spinner-loading">
              <Spinner animation="border" role="status">
                <span className="visually-hidden"></span>
              </Spinner>
            </div>
            <h3 className="mt-5 loading-popup-title">{t('idsc')}</h3>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default LoadingPopup
