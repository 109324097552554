import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import * as ar from "./ar.module.json";
import * as en from "./en.module.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ar: {
        translations: ar,
      },
      en: {
        translations: en,
      },
    },
    fallbackLng: "ar-EG",
    debug: false,
    lng: localStorage.getItem("i18nextLng") || "ar-EG",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
