import * as actionTypes from "../actions/actionTypes";

const userPreferences = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CURRENT_LANGUAGE:
      return { ...state, Lang: action.payload ?? "ar-EG" };

    default:
      return state;
  }
};

export default userPreferences;
