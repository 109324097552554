import { USER_DATA } from '../actions/actionTypes'

const userDataReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DATA:
      return {
        user: action.payload,
      }
    default:
      return state
  }
}
export default userDataReducer
