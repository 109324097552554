import { USER_DETAILS } from '../actions/actionTypes'

const userDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DETAILS:
      return {
        userDetails: action.payload,
      }
    default:
      return state
  }
}
export default userDetailsReducer
