import { combineReducers } from 'redux'
import userPreferences from './userPreferences'
import searchKeywordReducer from './searchKeyword'
import ratingReducer from './ratingReducer'
import userDataReducer from './userData'
import searchReducer from './SearchContent'
import userDetailsReducer from './userDetails'
import publicationSearchReducer from './publicationsSearchReducer'
import publicationSearchKeywordReducer from './publicationKeyword'
import proposalReducer from './proposalIdReducer'
export default combineReducers({
  userPreferences,
  searchKeywordReducer,
  ratingReducer,
  userDataReducer,
  searchReducer,
  userDetailsReducer,
  publicationSearchReducer,
  publicationSearchKeywordReducer,
  proposalReducer
})
