import { PUBLICATION_SEARCH } from '../actions/actionTypes'

const publicationSearchReducer = (state = [], action) => {

  switch (action.type) {
    case PUBLICATION_SEARCH:
      return {
        ...state,
        publicationSearchData: action.payload,
      }
    default:
      return state
  }
}
export default publicationSearchReducer
