import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { legacy_createStore, applyMiddleware } from "redux";
import promiseMiddleware from "redux-promise";
import rootReducer from "./reducers/index";

const persistConfig = {
  key: "root",
  storage,
  // blacklist: [],
};

const createStoreWithMiddleware =
  applyMiddleware(promiseMiddleware)(legacy_createStore);
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStoreWithMiddleware(persistedReducer);
export const persistor = persistStore(store);
