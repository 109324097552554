import { PROPOSAL_ID } from '../actions/actionTypes'

const proposalReducer = (state = {}, action) => {
  switch (action.type) {
    case PROPOSAL_ID :
      return {
        id: action.payload,
      }
    default:
      return state
  }
}
export default proposalReducer