import { RATING } from '../actions/actionTypes'

const ratingReducer = (state = {}, action) => {
  switch (action.type) {
    case RATING:
      return {
        rating: action.payload,
      }
    default:
      return state
  }
}
export default ratingReducer
